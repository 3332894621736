// src/components/Footer.js
import React from 'react';
import '../App.css'; // Import your CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">

      
      <div className="footer-content">
       <p dir="ltr">NETANEL HAZI כל הזכויות שמורות ©</p>
       <p>טלפון: 052-5100072 | אימייל: Natihazi7@gmail.com</p>
        {/* Add any additional information or links here */}
      </div>
    </footer>
  );
};

export default Footer;
